import { DeviceSpecificationActionTypes, DeviceSpecificationState, SET_IOS_SAFARI_PRIVATE_MODE } from './types'

const initialState: DeviceSpecificationState = {
  isIosSafariPrivateMode: false,
}

export function deviceSpecificationReducer(
  state: DeviceSpecificationState = initialState,
  action: DeviceSpecificationActionTypes,
): DeviceSpecificationState {
  switch (action.type) {
    case SET_IOS_SAFARI_PRIVATE_MODE:
      return {
        ...state,
        isIosSafariPrivateMode: true,
      }
    default:
      return state
  }
}
