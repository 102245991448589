export const SET_IOS_SAFARI_PRIVATE_MODE = 'SET_IOS_SAFARI_PRIVATE_MODE'

interface setIosSafariPrivateMode {
  type: typeof SET_IOS_SAFARI_PRIVATE_MODE
}

export interface DeviceSpecificationState {
  isIosSafariPrivateMode: boolean
}

export type DeviceSpecificationActionTypes = setIosSafariPrivateMode
