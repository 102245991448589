import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  ROUTE_PATH_CONTACT_DETAILS,
  ROUTE_PATH_DUPLICATE_BOOKING_WARNING,
  ROUTE_PATH_EXISTING_BOOKING_WARNING,
  ROUTE_PATH_IOS_SAFARI_PRIVATE_MODE,
  ROUTE_PATH_ORDER_CONFIRMATION,
  ROUTE_PATH_ORDER_PROCESSING,
  ROUTE_PATH_PARKING_OFFER,
  ROUTE_PATH_PARKING_REMINDERS,
  ROUTE_PATH_PARK_CARDS,
  ROUTE_PATH_PARK_CONFIRM_ORDER,
  ROUTE_PATH_PARK_CONFIRM_PAYMENT,
  ROUTE_PATH_PARK_DUPLICATE_WARNING,
  ROUTE_PATH_PARK_FREE_PARKING,
  ROUTE_PATH_PARK_GUEST_EDIT_DETAILS,
  ROUTE_PATH_PARK_GUEST_PAYMENT_DETAILS,
  ROUTE_PATH_PARK_GUEST_YOUR_DETAILS,
  ROUTE_PATH_PARK_OVERSTAY_WARNING,
  ROUTE_PATH_PARK_PARKING_PERMIT,
  ROUTE_PATH_PARK_PAYMENT_DECLINED,
  ROUTE_PATH_PARK_PROCESSING_ORDER,
  ROUTE_PATH_PARK_PROCESSING_PAYMENT,
  ROUTE_PATH_PARK_RESTRICTED_PARKING,
  ROUTE_PATH_PARK_SELECT_DURATION,
  ROUTE_PATH_PARK_SELECT_DURATION_CLIENT_REF,
  ROUTE_PATH_PARK_VEHICLES,
  ROUTE_PATH_PARK_YOUR_DETAILS,
  ROUTE_PATH_PAYMENT_DECLINED,
  ROUTE_PATH_PAYMENT_DETAILS,
  ROUTE_PATH_PAYMENT_DETAILS_GUEST,
  ROUTE_PATH_PAY_CONFIRM_SESSION,
  ROUTE_PATH_PAY_EXISTING_SESSION,
  ROUTE_PATH_PAY_PAYMENT_DETAILS,
  ROUTE_PATH_PAY_SESSION_DETAILS,
  ROUTE_PATH_PERMIT_WEB_API_LINK,
  ROUTE_PATH_QUICK_PARK,
  ROUTE_PATH_QUICK_PARK_PARKING_LOCATION,
  ROUTE_PATH_QUICK_PARK_SELECT_DURATION,
  ROUTE_PATH_SELECT_CARPARK_BY_OFFER,
  ROUTE_PATH_SELECT_DURATION,
  ROUTE_PATH_VEHICLE_DETAILS,
  ROUTE_PATH_VEHICLE_DETAILS_GUEST,
} from '../common/constants'
import { SESSION_STORAGE_KEY_BASKET_ID, SESSION_STORAGE_KEY_PARKING_QUOTE_ID } from '../common/types'
import VehicleExistsDialog from '../pages/parking/dialog/vehicle-exists-dialog'
import BasketExpiredHandler from '../pages/parking/error-handler/basket-expired-handler'
import { RootState } from '../store'
import { currentConsumer } from '../store/auth/reducers'
import { deleteParkingBasket } from '../store/basket/thunks'
import NotificationBar from '../store/notifications/notification-bar'
import {
  ROUTE_PATH_CARPARK_DETAILS,
  ROUTE_PATH_CASUAL_ACCESS_CONFIRM_ORDER,
  ROUTE_PATH_CASUAL_ACCESS_GUEST_PAYMENT_DETAILS,
  ROUTE_PATH_CASUAL_ACCESS_PARKING_SUMMARY,
  ROUTE_PATH_CASUAL_ACCESS_PAYMENT_DECLINED,
  ROUTE_PATH_CASUAL_ACCESS_PAYMENT_DETAILS,
  ROUTE_PATH_CASUAL_ACCESS_PAYMENT_PROCESSING,
  ROUTE_PATH_MANAGE_PARK,
} from '../store/park-and-pay/types'

interface ContainerLayoutProps {
  children: React.ReactNode
}

const ShowBasketExpiredLocations: Array<string> = [
  ROUTE_PATH_CARPARK_DETAILS,
  ROUTE_PATH_SELECT_DURATION,
  ROUTE_PATH_PARKING_OFFER,
  ROUTE_PATH_PARKING_REMINDERS,
  ROUTE_PATH_CONTACT_DETAILS,
  ROUTE_PATH_VEHICLE_DETAILS,
  ROUTE_PATH_VEHICLE_DETAILS_GUEST,
  ROUTE_PATH_PAYMENT_DETAILS,
  ROUTE_PATH_PAYMENT_DETAILS_GUEST,
  ROUTE_PATH_PAYMENT_DECLINED,
  ROUTE_PATH_ORDER_CONFIRMATION,
  ROUTE_PATH_ORDER_PROCESSING,
  ROUTE_PATH_CASUAL_ACCESS_PARKING_SUMMARY,
  ROUTE_PATH_CASUAL_ACCESS_PAYMENT_DETAILS,
  ROUTE_PATH_CASUAL_ACCESS_GUEST_PAYMENT_DETAILS,
  ROUTE_PATH_CASUAL_ACCESS_PAYMENT_PROCESSING,
  ROUTE_PATH_CASUAL_ACCESS_PAYMENT_DECLINED,
  ROUTE_PATH_CASUAL_ACCESS_CONFIRM_ORDER,
  ROUTE_PATH_PARK_SELECT_DURATION,
  ROUTE_PATH_PARK_SELECT_DURATION_CLIENT_REF,
  ROUTE_PATH_PARK_VEHICLES,
  ROUTE_PATH_PARK_CARDS,
  ROUTE_PATH_PARK_YOUR_DETAILS,
  ROUTE_PATH_PARK_GUEST_YOUR_DETAILS,
  ROUTE_PATH_PARK_GUEST_PAYMENT_DETAILS,
  ROUTE_PATH_PARK_GUEST_EDIT_DETAILS,
  ROUTE_PATH_PARK_CONFIRM_ORDER,
  ROUTE_PATH_PARK_CONFIRM_PAYMENT,
  ROUTE_PATH_PARK_PROCESSING_ORDER,
  ROUTE_PATH_PARK_PROCESSING_PAYMENT,
  ROUTE_PATH_PARK_PAYMENT_DECLINED,
  ROUTE_PATH_PARK_FREE_PARKING,
  ROUTE_PATH_PARK_RESTRICTED_PARKING,
  ROUTE_PATH_PARK_OVERSTAY_WARNING,
  ROUTE_PATH_PARK_DUPLICATE_WARNING,
  ROUTE_PATH_PARK_PARKING_PERMIT,
  ROUTE_PATH_QUICK_PARK,
  ROUTE_PATH_QUICK_PARK_PARKING_LOCATION,
  ROUTE_PATH_QUICK_PARK_SELECT_DURATION,
  ROUTE_PATH_EXISTING_BOOKING_WARNING,
  ROUTE_PATH_MANAGE_PARK,
  ROUTE_PATH_DUPLICATE_BOOKING_WARNING,
  ROUTE_PATH_PERMIT_WEB_API_LINK,
  ROUTE_PATH_SELECT_CARPARK_BY_OFFER,
  ROUTE_PATH_IOS_SAFARI_PRIVATE_MODE,
  ROUTE_PATH_PAY_EXISTING_SESSION,
  ROUTE_PATH_PAY_SESSION_DETAILS,
  ROUTE_PATH_PAY_PAYMENT_DETAILS,
  ROUTE_PATH_PAY_CONFIRM_SESSION,
]

const ParkingLayout: React.FC<ContainerLayoutProps> = (props) => {
  const { children } = props

  const location = useLocation()
  const dispatch = useDispatch()

  const { checkConsumerVehicleExistsResponse } = useSelector(
    (state: RootState) => ({
      checkConsumerVehicleExistsResponse: state.consumerVehicleReducer.checkConsumerVehicleExists,
    }),
    shallowEqual,
  )
  const consumer = useSelector(currentConsumer)

  const expiredLocations = (): number => {
    let count = 0
    ShowBasketExpiredLocations.forEach((route: string) => {
      if (location.pathname.toLowerCase().includes(route.toLowerCase())) {
        count += 1
      }
    })
    return count
  }

  const removeIds = () => {
    dispatch(deleteParkingBasket())
    localStorage.removeItem('declinedPayments')
    sessionStorage.removeItem(SESSION_STORAGE_KEY_BASKET_ID)
    sessionStorage.removeItem(SESSION_STORAGE_KEY_PARKING_QUOTE_ID)
  }

  return (
    <>
      <NotificationBar />
      {expiredLocations() > 0 ? <BasketExpiredHandler /> : removeIds()}
      {consumer && checkConsumerVehicleExistsResponse && (
        <VehicleExistsDialog
          currentConsumer={consumer}
          checkConsumerVehicleExistsResponse={checkConsumerVehicleExistsResponse}
        />
      )}
      {children}
    </>
  )
}
export default ParkingLayout
