import { BASE_DISTRIBUTOR_MEMBER_URL } from '../../config/axios/types'

export interface PayParkingReq {
  kind: string
  ticketId: string
  consumerId?: string
}

export interface ParkingTicket {
  ticketId: string
  startDateTime: string
  facilityName: string
  timeZone: string
  plate?: string
  amountDue: number
}

export interface ParkingTicketState {
  parkingTicket: ParkingTicket | null
}

export const GET_TICKET_BY_LICENSE_PLATE_LIST_API_RESOURCE_PATH =
  '/public/operators/:operatorId/tickets:lookupByPlateList'
export const GET_DISTRIBUTOR_TICKET_BY_LICENSE_PLATE_LIST_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/tickets:lookupByPlateList`
export const GET_TICKET_BY_LICENSE_PLATE_API_RESOURCE_PATH = '/public/operators/:operatorId/tickets:lookupByPlate'
export const GET_DISTRIBUTOR_TICKET_BY_LICENSE_PLATE_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/tickets:lookupByPlate`
export const GET_TICKET_BY_NUMBER_API_RESOURCE_PATH = '/public/operators/:operatorId/tickets:lookupByNumber'
export const GET_DISTRIBUTOR_TICKET_BY_NUMBER_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/tickets:lookupByNumber`
export const PAY_PARKING_TICKET_API_RESOURCE_PATH = '/public/operators/:operatorId/baskets:payParking'
export const PAY_DISTRIBUTOR_PARKING_TICKET_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/baskets:payParking`

export const GET_PARKING_TICKET_REQUEST = 'GET_PARKING_TICKET_REQUEST'
export const GET_PARKING_TICKET_SUCCESS = 'GET_PARKING_TICKET_SUCCESS'

export interface getParkingTicketRequest {
  type: typeof GET_PARKING_TICKET_REQUEST
}

export interface getParkingTicketSuccess {
  type: typeof GET_PARKING_TICKET_SUCCESS
  payload: {
    data: ParkingTicket
  }
}

export type ParkingTicketActionTypes = getParkingTicketRequest | getParkingTicketSuccess
