import {
  GET_PARKING_TICKET_REQUEST,
  GET_PARKING_TICKET_SUCCESS,
  ParkingTicketActionTypes,
  ParkingTicketState,
} from './types'

const initialState: ParkingTicketState = {
  parkingTicket: null,
}

export function parkingTicketReducer(
  state: ParkingTicketState = initialState,
  action: ParkingTicketActionTypes,
): ParkingTicketState {
  switch (action.type) {
    case GET_PARKING_TICKET_REQUEST:
      return {
        ...state,
        parkingTicket: null,
      }
    case GET_PARKING_TICKET_SUCCESS:
      return {
        ...state,
        parkingTicket: action.payload.data,
      }
    default:
      return state
  }
}
