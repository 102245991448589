import detectIncognito from 'detectincognitojs'
import { useEffect } from 'react'
import { isSafari } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { setIosSafariPrivateMode } from '../../store/device specification/actions'

const useDetectIosSafariPrivateMode = () => {
  const dispatch = useDispatch()
  const isIPhone = /iPhone/i.test(navigator.userAgent)

  useEffect(() => {
    detectIncognito().then((result) => {
      if (isIPhone && isSafari) {
        if (result.isPrivate) {
          console.log('Is Private Mode ==>', result.isPrivate)
          dispatch(setIosSafariPrivateMode())
        }
      }
    })
    // eslint-disable-next-line
  }, [])
}

export default useDetectIosSafariPrivateMode
