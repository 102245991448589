import { AccessMode, BayReservedType } from '../../common/types'
import { BASE_DISTRIBUTOR_MEMBER_URL } from '../../config/axios/types'
import { OrganisationType } from '../auth/types'
import { Stay } from '../entitlement-details/types'
import { Slot } from '../entitlements/types'
import { AgreementStatus } from '../my-agreements/types'

export const GET_FLEET_ENTITLEMENTS_REQUEST = 'GET_FLEET_ENTITLEMENTS_REQUEST'
export const GET_FLEET_ENTITLEMENTS_SUCCESS = 'GET_FLEET_ENTITLEMENTS_SUCCESS'
export const RESET_FLEET_ENTITLEMENTS = 'RESET_FLEET_ENTITLEMENTS'

export const GET_FLEET_ENTITLEMENT_DETAILS_SUCCESS = 'GET_FLEET_ENTITLEMENT_DETAILS_SUCCESS'
export const RESET_FLEET_ENTITLEMENT_DETAILS = 'RESET_FLEET_ENTITLEMENT_DETAILS'

export const GET_FLEET_ENTITLEMENT_VEHICLES_REQUEST = 'GET_FLEET_ENTITLEMENT_VEHICLES_REQUEST'
export const GET_FLEET_ENTITLEMENT_VEHICLES_SUCCESS = 'GET_FLEET_ENTITLEMENT_VEHICLES_SUCCESS'

export const GET_CONSUMER_FLEET_ENTITLEMENTS_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/fleet-entitlements'
export const GET_DISTRIBUTOR_FLEET_ENTITLEMENTS_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/fleet-entitlements`
export const GET_CONSUMER_FLEET_ENTITLEMENT_BY_ID_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/fleet-entitlements/:entitlementId'
export const GET_DISTRIBUTOR_FLEET_ENTITLEMENT_BY_ID_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/fleet-entitlements/:entitlementId`
export const GET_CONSUMER_FLEET_ENTITLEMENT_VEHICLES_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/fleet-entitlements/:entitlementId/vehicles'
export const GET_DISTRIBUTOR_FLEET_ENTITLEMENT_VEHICLES_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/fleet-entitlements/:entitlementId/vehicles`

export enum AccessControlSystemType {
  External = 'External',
  Nuevo = 'Nuevo',
}

export interface FleetEntitlement {
  id: string
  reference: string
  facilityName: string
  bayReserveType: BayReservedType
  agreementStatus: AgreementStatus
  timeZoneId: string
  createdInstant: number
  createdDateTime: string
  facilityId: string
  facilityPublicId: string
  facilityPublicName: string
  organisationName: string
  allocationCount: number
  consumerCount: number
  vehicleCount: number
  organisationId: string
  organisationType: OrganisationType
  startDate: string
  finishDate: string
  slots: Slot[]
}

export interface FleetEntitlementDetails {
  id: string
  reference: string
  specifyTime: boolean
  exclusive: boolean
  timeZoneId: string
  stay: Stay
  version: number
  agreementStatus: AgreementStatus
  accessModeMap: {
    QR: string
  }
  createdInstant: number
  createdDateTime: string
  facilityId: string
  facilityName: string | null
  facilityPublicId: string
  facilityPublicName: string
  bayReservedType: BayReservedType
  accessControlSystemType: AccessControlSystemType
  consumerGroupId: string
  organisationId: string
  organisationType: OrganisationType
  organisationName: string
  allocationCount: number
  consumerCount: number
  vehicleCount: number
}

export interface FleetEntitlementVehicle {
  entitlementId: string
  agreementReference: string
  makeCode: string
  plate: string
  plateIssuer: string
  nickName: string | null
  rfidCredential: string | null
  qrCredential: QRCredential
  barCodeCredential: string | null
  version: number
}

export interface QRCredential {
  id: string
  entitlementId: string
  accessModeType: AccessMode
  credentialValue: string
}

interface getFleetEntitlementsRequestAction {
  type: typeof GET_FLEET_ENTITLEMENTS_REQUEST
}

interface getFleetEntitlementsSuccessAction {
  type: typeof GET_FLEET_ENTITLEMENTS_SUCCESS
  payload: {
    data: FleetEntitlement[]
  }
}

interface getFleetEntitlementDetailsSuccessAction {
  type: typeof GET_FLEET_ENTITLEMENT_DETAILS_SUCCESS
  payload: {
    data: FleetEntitlementDetails | null
  }
}

interface getFleetEntitlementVehiclesRequestAction {
  type: typeof GET_FLEET_ENTITLEMENT_VEHICLES_REQUEST
}

interface getFleetEntitlementVehiclesSuccessAction {
  type: typeof GET_FLEET_ENTITLEMENT_VEHICLES_SUCCESS
  payload: {
    data: FleetEntitlementVehicle[]
  }
}

interface resetFleetEntitlementsAction {
  type: typeof RESET_FLEET_ENTITLEMENTS
}

interface resetFleetEntitlementDetailsAction {
  type: typeof RESET_FLEET_ENTITLEMENT_DETAILS
}

export interface FleetEntitlementsState {
  fleetEntitlementsLoading: boolean
  fleetEntitlementsSuccess: boolean
  fleetEntitlements: FleetEntitlement[]
  fleetEntitlementsRequestFinished: boolean
  fleetEntitlementDetailsRequestFinished: boolean
  fleetEntitlement: FleetEntitlementDetails | null
  fleetEntitlementVehiclesRequestFinished: boolean
  fleetEntitlementVehicles: FleetEntitlementVehicle[]
}

export type FleetEntitlementsActionTypes =
  | getFleetEntitlementsRequestAction
  | getFleetEntitlementsSuccessAction
  | resetFleetEntitlementsAction
  | getFleetEntitlementDetailsSuccessAction
  | getFleetEntitlementVehiclesRequestAction
  | getFleetEntitlementVehiclesSuccessAction
  | resetFleetEntitlementDetailsAction
